/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Image} from "smooth-doc/components";
function _createMdxContent(props) {
  const _components = Object.assign({
    blockquote: "blockquote",
    p: "p",
    a: "a",
    h1: "h1",
    div: "div",
    code: "code",
    h2: "h2",
    ul: "ul",
    li: "li",
    pre: "pre",
    img: "img"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "This article was first published @medium: ", React.createElement(_components.a, {
    href: "https://medium.com/@ivangsa/stop-writing-yaml-for-openapi-use-a-compact-dsl-and-save-time-and-typing-574a138faddc"
  }, "Stop writing YAML for OpenAPI, use a compact DSL and save time and typing"), "."), "\n"), "\n", React.createElement(_components.h1, {
    id: "generating-openapi-definition-files-from-zenwave-domain-language-models-with-zenwavesdk",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#generating-openapi-definition-files-from-zenwave-domain-language-models-with-zenwavesdk",
    "aria-label": "generating openapi definition files from zenwave domain language models with zenwavesdk permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Generating OpenAPI definition files from \"ZenWave Domain Language\" models with ZenWaveSDK"), "\n", React.createElement(_components.p, null, "Because writing YAML by hand is now fun..."), "\n", React.createElement(_components.p, null, "You can use ", React.createElement(_components.code, null, "ZenWave Domain Language"), " models as IDL to generate OpenAPI v3 with ZenWave SDK."), "\n", React.createElement(_components.p, null, "Imagine being able to quickly define an API in a more concise and readable way, without losing the rigor of an OpenAPI specification. This article explores how you can leverage ", React.createElement(_components.a, {
    href: "https://www.zenwave360.io/docs/event-driven-design/zenwave-domain-language"
  }, "ZenWave Domain Language (ZDL)"), ", a compact developer-friendly DSL, to generate OpenAPI definitions, simplifying the process of creating API documentation, reducing errors, and improving developer productivity. Whether you're managing an extensive API or just getting started, a DSL might be the key to making your API workflow more efficient."), "\n", React.createElement(_components.p, null, "This is how it looks ZenWave ZDL as OpenAPI IDL (fragment):"), "\n", React.createElement(Image, {
    alt: "This is how it looks ZenWave ZDL as OpenAPI IDL (fragment)",
    dark: "./ZDL-To-OpenAPI-IDL-fragment-dark.png",
    light: "./ZDL-To-OpenAPI-IDL-fragment-light.png"
  }), "\n", React.createElement(_components.h2, {
    id: "zenwave-domain-language-zdl",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#zenwave-domain-language-zdl",
    "aria-label": "zenwave domain language zdl permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "ZenWave Domain Language (ZDL)"), "\n", React.createElement(_components.p, null, "Inspired by JHipster JDL, ZDL is a language for describing DDD Bounded Contexts, including domain entities and their relationships, services, commands, events and business policies... for Event-Driven Architectures."), "\n", React.createElement(_components.p, null, "It's designed to be compact, readable and expressive. Developer friendly, and machine friendly. It works well as an Ubiquitous Language format."), "\n", React.createElement(_components.p, null, "It can also be used as an IDL for authoring OpenAPI (and AsyncAPI) definition files."), "\n", React.createElement("img", {
    src: "https://www.zenwave360.io/static/EventStorming-ZDL-Mapping-cb9c987d5d0aff110c5890481034ae20.png"
  }), "\n", React.createElement(_components.h2, {
    id: "creating-a-zdl-for-authoring-an-openapi-definition",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#creating-a-zdl-for-authoring-an-openapi-definition",
    "aria-label": "creating a zdl for authoring an openapi definition permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Creating a ZDL for authoring an OpenAPI definition"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "As a minimum requirement, you need a ", React.createElement(_components.code, null, "service"), " and an ", React.createElement(_components.code, null, "aggregate entity"), " for this service."), "\n", React.createElement(_components.li, null, "You can use this ", React.createElement(_components.code, null, "entity"), " as request and response objects or you can define separate DTOs for this purpose using ", React.createElement(_components.code, null, "input"), "and ", React.createElement(_components.code, null, "output"), " entities."), "\n", React.createElement(_components.li, null, "You can also define and reference ", React.createElement(_components.code, null, "enums"), " and ", React.createElement(_components.code, null, "relationships"), " between entities. Nested entities and arrays also work."), "\n", React.createElement(_components.li, null, "Lastly you need to define the service methods and their parameters and annotate them using ", React.createElement(_components.code, null, "@rest"), ", ", React.createElement(_components.code, null, "@post"), ", ", React.createElement(_components.code, null, "@get"), ", ", React.createElement(_components.code, null, "@put"), ", ", React.createElement(_components.code, null, "@delete"), ", ", React.createElement(_components.code, null, "@paginated"), ", ", React.createElement(_components.code, null, "@inline"), " annotations."), "\n"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-zdl"
  }, "@aggregate\r\nentity PaymentMethod {\r\n    type PaymentMethodType required\r\n    cardNumber String required minlength(16) maxlength(16)\r\n}\r\n\r\nenum PaymentMethodType { VISA(1), MASTERCARD(2) }\r\n\r\n@rest(\"/payment-methods\")\r\nservice PaymentsService for (PaymentMethod) {\r\n    @post\r\n    doSomethingWithANewPayment(PaymentMethod) PaymentMethod\r\n\r\n    @put(\"/{id}\")\r\n    doSomethingWithAnExistingPayment(id, PaymentMethod) PaymentMethod?\r\n}\n")), "\n", React.createElement(_components.p, null, "NOTE: service method only accept two kind of parameters: ", React.createElement(_components.code, null, "id"), " and command payload (that will map to the request body), but you can use ", React.createElement(_components.code, null, "@inline"), " to expand fields as request path parameters (see below)."), "\n", React.createElement(_components.p, null, "Checkout the ", React.createElement(_components.a, {
    href: "https://www.zenwave360.io/docs/event-driven-design/zenwave-domain-language#services-and-commands"
  }, "ZDL documentation"), " for more details about command methods."), "\n", React.createElement(_components.h2, {
    id: "install-zenwave-sdk-using-jbang",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#install-zenwave-sdk-using-jbang",
    "aria-label": "install zenwave sdk using jbang permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Install ZenWave SDK Using JBang"), "\n", React.createElement(_components.p, null, "Install an evergreen self updating ZenWave SDK CLI using JBang:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "jbang alias add --fresh --name=zw release@zenwave360/zenwave-sdk\n")), "\n", React.createElement(_components.p, null, "Following these instructions for complete details about JBang and IntelliJ Editor: ", React.createElement(_components.a, {
    href: "https://www.zenwave360.io/docs/getting-started/"
  }, "https://www.zenwave360.io/docs/getting-started/")), "\n", React.createElement(_components.p, null, "Now you can use jbang zw to generate a complete OpenAPI definition file from your ZDL model."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "jbang zw -p ZDLToOpenAPIPlugin \\\r\nspecFile=model.zdl \\\r\nidType=integer \\\r\nidTypeFormat=int64 \\\r\ntargetFolder=. \\\r\ntargetFile=payments-openapi.yml\n")), "\n", React.createElement(_components.p, null, "Or use ZenWave ZDL Editor for IntelliJ configuring the generator plugin on top of your zdl file:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-zdl"
  }, "config {\r\n    plugins {\r\n        /** Use ZenWave Editor for IntelliJ IDEA to run this */\r\n        ZDLToOpenAPIPlugin {\r\n            idType integer\r\n            idTypeFormat int64\r\n            targetFolder \".\"\r\n            targetFile \"openapi.yml\"\r\n        }\r\n    }\r\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "./RunWith-ZenWave-Editor-for-IntelliJ.png",
    alt: "Run With ZenWave Editor for IntelliJ"
  })), "\n", React.createElement(_components.p, null, "Then, check the generated OpenAPI definition file payments-openapi.yml, and see for yourself how much typing you saved!"), "\n", React.createElement(_components.h2, {
    id: "expanding-fields-as-request-path-parameters",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#expanding-fields-as-request-path-parameters",
    "aria-label": "expanding fields as request path parameters permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Expanding fields as request path parameters"), "\n", React.createElement(_components.p, null, "You can use ", React.createElement(_components.code, null, "@inline"), " ", React.createElement(_components.code, null, "ìnputs"), " to expand fields as request path parameters (and service method parameters)."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-zdl"
  }, "@inline // expand fields as request parameters\r\ninput PaymentMethodInput {\r\n    cardNumber String\r\n    paymentMethod PaymentMethod\r\n}\r\n\r\n@rest(\"/customers\")\r\nservice PaymentsService for (PaymentMethod) {\r\n\r\n    @put(\"/{paymentMethodId}/cardNumber/{cardNumber}\") // see example below to specify param types\r\n    updatePaymentMethodByCardNumber(PaymentMethodInput) PaymentMethod?\r\n}\n")), "\n", React.createElement(_components.p, null, "It will pick the first parameter from the entity id and the remaining parameters will be configured as ", React.createElement(_components.code, null, "string")), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "./InlinePathParameters-OpenAPI-Generated.png",
    alt: "Inline Path Parameters OpenAPI Generated"
  })), "\n", React.createElement(_components.p, null, "But you can override the path params with configuration, see complete example below."), "\n", React.createElement(_components.h2, {
    id: "complete-zdl-example",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#complete-zdl-example",
    "aria-label": "complete zdl example permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Complete ZDL Example"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-zdl"
  }, "@aggregate\r\nentity Customer {\r\n    name String required maxlength(254) /** Customer name */\r\n    email String required maxlength(254) pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$/)\r\n    /** Customer Addresses can be stored in a JSON column in the database. */\r\n    @json addresses Address[] minlength(1) maxlength(5) {\r\n        street String required maxlength(254)\r\n        city String required maxlength(254)\r\n    }\r\n}\r\n\r\nentity PaymentMethod {\r\n    type PaymentMethodType required\r\n    cardNumber String required\r\n}\r\n\r\nenum PaymentMethodType { VISA(1), MASTERCARD(2) }\r\n\r\nrelationship OneToMany {\r\n    Customer{paymentMethods required maxlength(3)} to PaymentMethod{customer required}\r\n}\r\n\r\n// you can create 'inputs' as dtos for your service methods, or use entities directly\r\ninput CustomerSearchCriteria {\r\n    name String\r\n    email String\r\n    city String\r\n    state String\r\n}\r\n\r\n@inline // expand fields as request parameters (and service method parameters)\r\ninput AddressInput {\r\n    addressId String\r\n    address Address\r\n}\r\n\r\n@rest(\"/customers\")\r\nservice CustomerService for (Customer) {\r\n    @post\r\n    createCustomer(Customer) Customer\r\n    @get(\"/{id}\")\r\n    getCustomer(id) Customer?\r\n    @put(\"/{id}\")\r\n    updateCustomer(id, Customer) Customer?\r\n    @put({ path: \"/{customerId}/address/{addressId}\", params: {addressId: Long} }) // specify param types\r\n    updateCustomerAddress(id, AddressInput) Customer?\r\n    @delete(\"/{id}\")\r\n    deleteCustomer(id)\r\n    @post(\"/search\")\r\n    @paginated\r\n    searchCustomers(CustomerSearchCriteria) Customer[]\r\n}\n")), "\n", React.createElement(_components.p, null, "Run:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, "jbang zw -p io.zenwave360.sdk.plugins.ZDLToOpenAPIPlugin \\\r\n    specFile=customers-model.zdl \\\r\n    idType=integer \\\r\n    idTypeFormat=int64 \\\r\n    targetFolder=. \\\r\n    targetFile=openapi.yml\n")), "\n", React.createElement(_components.p, null, "And get surprised by the amount of YAML typing you saved!"), "\n", React.createElement(_components.p, null, "Happy coding! 🚀"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
